import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
// import NavDropdown from "react-bootstrap/NavDropdown";

function ColorSchemesExample() {
    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="#home">Covid19 Realtime data</Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/india">
                            India
                        </Nav.Link>
                        <Nav.Link as={Link} to="/world">
                            World
                        </Nav.Link>
                        {/* <Nav.Link href="india">India</Nav.Link>
            <Nav.Link href="world">World</Nav.Link> */}
                    </Nav>
                </Container>
            </Navbar>
            <br />
        </>
    );
}

export default ColorSchemesExample;
