// import * as Bootstrap from "react-bootstrap";
// npm uninstall react-router-dom
// npm install react-router-dom@5.2

import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ColorSchemesExample from "./Components/Header.js";
import India from "./Components/India";
import World from "./Components/World";

class App extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BrowserRouter>
                <div className=" container-fluid">
                    <ColorSchemesExample />
                    <Routes>
                        <Route path="/" element={<India />} />
                        <Route path="/world" element={<World />} />
                        <Route path="/india" element={<India />} />
                    </Routes>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
