import React, { Component } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";

class World extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        axios.get("https://covid-api.mmediagroup.fr/v1/cases").then((val) => {
            const changer = Object.entries(val.data);
            this.setState({ data: changer });
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Country</th>
                                <th>Confirmed</th>
                                <th>Death</th>
                                <th>Continent</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((val, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{val[0]}</td>
                                        <td>{val[1].All.confirmed}</td>
                                        <td>{val[1].All.deaths}</td>
                                        <td>{val[1].All.continent}</td>
                                        <td>{val[1].All.location}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}

export default World;
