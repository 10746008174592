import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

class Statedata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateData: "{}",
      confirmed_cases_cn: 0,
      active_cases_cn: 0,
      recovered_cases_cn: 0,
      death_cases_cn: 0,
    };
  }

  componentDidMount() {
    axios
      .get("https://data.covid19india.org/state_district_wise.json")
      .then((val) => {
        this.setState({ stateData: val.data });
      });
  }

  //    rendering
  render() {
    let keys = Object.keys(this.state.stateData);

    //   Countrywise total

    return (
      <div className="row">
        <div className="col-md-12">
          <Accordion defaultActiveKey="0">
            {/* sl9icing to avoid 1 empty data */}
            {keys.slice(1).map((state, i) => {
              let district = this.state.stateData[state].districtData;
              let district_keys = Object.keys(district || {});

              let confirmed_cases = 0;
              let active_cases = 0;
              let recovered_cases = 0;
              let death_cases = 0;
              let district_info = [];

              // iterating objects
              for (const x in district) {
                confirmed_cases += district[x].confirmed;
                active_cases += district[x].active;
                recovered_cases += district[x].recovered;
                death_cases += district[x].deceased;
                let allCovidInfo = district[x];
                allCovidInfo["districtname"] = x;
                district_info.push(allCovidInfo);
              }

              //   Countrywise total
              this.state.confirmed_cases_cn += confirmed_cases;
              this.state.active_cases_cn += active_cases;
              this.state.recovered_cases_cn += recovered_cases;
              this.state.death_cases_cn += death_cases;

              return (
                // <div class="card bg-primary"></div>
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header>
                    {state}
                    <span className="btn btn-dark p-1 ms-2 ">
                      Total - {confirmed_cases}
                    </span>
                    <span className="btn btn-dark p-1 ms-2">
                      {" "}
                      Active - {active_cases}
                    </span>{" "}
                    <span className="btn btn-dark p-1 ms-2">
                      Recovered - {recovered_cases}{" "}
                    </span>
                    <span className="btn btn-dark p-1 ms-2">
                      {" "}
                      Death - {death_cases}{" "}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <td>District</td>
                          <td>Confirmed</td>
                          <td>Active</td>
                          <td>Recovered</td>
                          <td>Deaths</td>
                        </tr>
                      </thead>
                      <tbody>
                        {district_info.map((val, i) => {
                          return (
                            <tr key={i}>
                              <td>{val.districtname}</td>
                              <td>{val.confirmed}</td>
                              <td>{val.active}</td>
                              <td>{val.recovered}</td>
                              <td>{val.deceased}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </div>
      </div>
    );
  }
}

export default Statedata;
