// Importing
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Statedata from "./States";
import axios from "axios";

// Class defining
class India extends Component {
  constructor(props) {
    super(props);
    this.state = {
      india: {},
    };
  }

  componentDidMount() {
    axios
      .get("https://covid-api.mmediagroup.fr/v1/cases?country=India")
      .then((val) => {
        this.setState({ india: val.data.All });
      });
  }
  // Rendering
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <img src="https://flagcdn.com/48x36/in.png" />
          <h3>India</h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-3 float-left "></div>
            <Card className="badge bg-info ms-4 " style={{ width: "18rem" }}>
              <Card.Body className="text-center ">
                <Card.Title>Total Cases</Card.Title>
                <Card.Subtitle className="mb-2 text-muted ">‎ </Card.Subtitle>
                {/* <Card.Title>{this.state.india.confirmed}</Card.Title> */}
                <Card.Title>99</Card.Title>
                {/* <Card.Text>25</Card.Text> */}
              </Card.Body>
            </Card>

            <Card className="badge bg-warning ms-4 " style={{ width: "18rem" }}>
              <Card.Body className="text-center ">
                <Card.Title>Active Cases</Card.Title>
                <Card.Subtitle className="mb-2 text-muted ">‎</Card.Subtitle>
                <Card.Title>0</Card.Title>
                {/* <Card.Text>25</Card.Text> */}
              </Card.Body>
            </Card>
            <Card
              className="badge ms-4 mt-4 bg-success "
              style={{ width: "18rem" }}
            >
              <Card.Body className="text-center ">
                <Card.Title>Recovered</Card.Title>
                <Card.Subtitle className="mb-2 text-muted ">‎</Card.Subtitle>
                <Card.Title>0</Card.Title>
                {/* <Card.Text>25</Card.Text> */}
              </Card.Body>
            </Card>
            <Card className="badge bg-danger ms-4 " style={{ width: "18rem" }}>
              <Card.Body className="text-center ">
                <Card.Title>Deaths</Card.Title>
                <Card.Subtitle className="mb-2 " style={{ color: "blue" }}>
                  ‎
                </Card.Subtitle>
                <Card.Title>25</Card.Title>
                {/* <Card.Text>25</Card.Text> */}
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="col-md-12">
          <br />
          <Statedata />
        </div>
      </div>
    );
  }
}

export default India;
